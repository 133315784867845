import React, { useEffect, useState } from "react";
import Header from "./../components/Header";
import { Link } from "react-router-dom";
import Message from "./../components/LoadingError/Error";
import { useDispatch, useSelector } from "react-redux";
import { listCarDetails } from "../Redux/Actions/CarsActions";
import Loading from "../components/LoadingError/Loading";
import Toast from "../components/LoadingError/Toast";
import { LEAD_CREATE_RESET } from "../Redux/Constants/LeadsConstants";
import { createLead } from "../Redux/Actions/LeadsActions";

const SingleProduct = ({ match }) => {
  const carID = match.params.id; 
  const dispatch = useDispatch();

  const carDetails = useSelector((state) => state.carDetails);
  const {loading, error, car } = carDetails; 
  useEffect(() => {
    dispatch(listCarDetails(carID))
  }, [dispatch, carID]);

  const [actual_image, setActualImage] = useState(0);
  const n_images = car && car.images ? car.images.length - 1 : null

  function nextImage()
  {
    setActualImage(actual_image + 1);
  }

  function prevImage()
  {
    setActualImage(actual_image - 1);
  }

  const price = car && car.price ? car.price : null
  const car_name = car && car.car_name ? car.car_name : null
  const registration = car && car.registration ? car.registration : null
  const vin = car && car.vin ? car.vin : null
  const brand = car && car.brand ? car.brand: null
  const model = car && car.model  ? car.model : null
  const fuel = car && car.fuel  ? car.fuel : null
  const segment = car && car.segment ? car.segment: null
  const transmition = car && car.transmition  ? car.transmition : null
  const year_origin = car && car.year_origin ? car.year_origin : null
  const month_origin = car && car.month_origin ? car.month_origin : null
  const kilometers = car && car.kilometers ? car.kilometers : null
  const displacement = car && car.displacement ? car.displacement : null
  const power = car && car.power ? car.power : null
  const ports = car && car.ports ? car.ports : null
  const provider = car && car.provider ? car.provider : null
  const color = car && car.color ? car.color : null
  const warranty = car && car.warranty ? car.warranty : null
  const stand_location = car && car.stand_location ? car.stand_location : null
  const security_preformance = car && car.security_preformance ? car.security_preformance : null
  const inside_material = car && car.inside_material ? car.inside_material : null
  const outside_material = car && car.outside_material ? car.outside_material : null
  const multimedia_confort = car && car.multimedia_confort ? car.multimedia_confort : null

  const [name, setname] = useState("")
  const [email, setemail] = useState("")
  const [phone, setphone] = useState("")
  const [message, setmessage] = useState("Estou interessado neste carro.")
  const [car_registration, setcar_registration] = useState(registration)
  const [car_name_lead, setcar_name_lead] = useState(car_name)

  const dispatch_lead = useDispatch()

  const leadCreate = useSelector((state) => state.leadCreate)
  const {loading:loading_lead, error:error_lead, lead} = leadCreate

  useEffect(()=>{

    if (lead) {
      dispatch({type:LEAD_CREATE_RESET})
      setname("")
      setemail("")
      setphone("")
      setmessage("")
    }
  },[lead, dispatch])


  const submitHandler = (e) => {
    e.preventDefault()
    dispatch_lead(createLead(name, email, phone,  message, registration, car_name))
    dispatch({type:LEAD_CREATE_RESET})
    setname("")
    setemail("")
    setphone("")
  }

  return (
    <>
    <Toast/>
      <Header />
      <div className="container single-product">
      {
      loading || security_preformance == null || inside_material == null || outside_material == null ||  multimedia_confort == null ? (
        <div className="mb-5">
      <Loading/>
        </div>
      ) : error ? (
        <Message variant="alert-danger">{error}</Message>
      )
      :
      (
        <>
        <div className="row">
          <div className="col-md-6">
            <div className="single-image">
              {actual_image != 0 ? (<button className="button_image bil" onClick={() => prevImage()}> <i class="arrow left"></i></button>) : null}
              
              <img src={car && car.images ? car.images[actual_image] : null} alt={car && car.car_name ? car.car_name : null} />

              {actual_image != n_images ? <button className="button_image bir" onClick={() => nextImage()}> <i class="arrow right"></i></button> : null}

            </div>
          </div>

          <div className="col-md-6">
            <div className="product-dtl">
              <div className="product-info">
                <div className="product-name">{car_name}</div>
              </div>
              <p><b>{brand} {model}</b></p>
              <p>{price} € | {kilometers} Kms | {fuel} | {month_origin}-{year_origin} </p>

              <div className="product-count col-lg-8 ">
              <div className="flex-box d-flex justify-content-between align-items-center">
                  <h6 className="fas fa-road"> Número de Quilômetros</h6>
                   
                  <span>{kilometers} Kms</span>
                </div>

                <div className="flex-box d-flex justify-content-between align-items-center">
                  <h6 className="fas fa-gas-pump"> Combustível</h6>
                   
                  <span>{fuel}</span>
                </div>

                <div className="flex-box d-flex justify-content-between align-items-center">
                  <h6 className="fas fa-calendar-alt"> Mês e Ano</h6>
                   
                  <span>{month_origin}-{year_origin}</span>
                </div>

                <div className="flex-box d-flex justify-content-between align-items-center">
                  <h6 className="fas fa-car-side"> Tipo</h6>
                   
                  <span>{segment}</span>
                </div>

                <div className="flex-box d-flex justify-content-between align-items-center">
                 <h6 className="fas fa-paint-brush"> Cor</h6>
                    
                  <span>{color}</span>
                </div>
                
                <div className="flex-box d-flex justify-content-between align-items-center">
                 <h6 className="fas fa-fire"> Cilindrada</h6>
                    
                  <span>{displacement} cc</span>
                </div>
                <div className="flex-box d-flex justify-content-between align-items-center">
                 <h6 className="fas fa-tachometer-alt"> Potência</h6>
                    
                  <span>{power} cv</span>
                </div>

                <div className="flex-box d-flex justify-content-between align-items-center">
                 <h6 className="fas fa-key"> Portas</h6>
                    
                  <span>{ports}</span>
                </div>

                <div className="flex-box d-flex justify-content-between align-items-center">
                 <h6 className="fas fa-caret-square-right"> Transmissão</h6>
                    
                  <span>{transmition}</span>
                </div>


              </div>


            </div>
          </div>
        </div>

        {/* Contact */}

        <div className="row my-5">
          <div className="col-md-6">
          
            <div className="mb-5 mb-md-2  p-3 shadow-sm ">
            <h6 className="mb-3"><b>Segurança e Desempenho</b></h6>
            <table className='table_info'>
            
              {security_preformance.map((sp, index) => (

                  index % 2 == 0 ? 
                  (<tr className='tr_info'> 
                  <td className='td_info'>{security_preformance[index]}</td> 
                  <td className='td_info'>{security_preformance[index + 1]}</td> 
                  </tr>) 
                  : null 

                ))}
            
          </table>
          </div>
            
          <div className="mb-5 mb-md-2  p-3 shadow-sm ">
          <h6 className="mb-3"><b>Equipamento Interno</b></h6>
          <table className='table_info'>
            
            {inside_material.map((sp, index) => (

                index % 2 == 0 ? 
                (<tr className='tr_info'> 
                <td className='td_info'>{inside_material[index]}</td> 
                <td className='td_info'>{inside_material[index + 1]}</td> 
                </tr>) 
                : null 

              ))}
          
        </table>
        </div>

        <div className="mb-5 mb-md-2  p-3 shadow-sm ">
          <h6 className="mb-3"><b>Equipamento Externo</b></h6>
          <table className='table_info'>
            
            {outside_material.map((sp, index) => (

                index % 2 == 0 ? 
                (<tr className='tr_info'> 
                <td className='td_info'>  {outside_material[index]} </td> 
                <td className='td_info'> {outside_material[index + 1]}</td> 
                </tr>) 
                : null 

              ))}
          
        </table>
        </div>

        <div className="mb-5 mb-md-2 p-3 shadow-sm">
          <h6 className="mb-3"><b>Conforto e Multimédia</b></h6>
          <table className='table_info'>
            
            {multimedia_confort.map((sp, index) => (

                index % 2 == 0 ? 
                (<tr className='tr_info'> 
                <td className='td_info'>{multimedia_confort[index]}</td> 
                <td className='td_info'>{multimedia_confort[index + 1]}</td> 
                </tr>) 
                : null 

              ))}
          
        </table>
        </div>

          </div>
          <div className="col-md-6" >
            <h5><span><b>Têm interesse neste veiculo? Entraremos em contacto consigo! Preencha o formulário sem compromisso.</b> </span></h5>
            <div className="my-4"></div>
            <div className="my-4"> 
            <div className="subscribe-head" onSubmit={submitHandler}>
              
              <form className="form-section" >
              <input required placeholder="Nome" name="text" type="text" value={name} onChange={(e) => setname(e.target.value)}/>
              <input required placeholder="Telemóvel" name="phone" type="phone" value={phone} onChange={(e) => setphone(e.target.value)}/>
              <input required placeholder="exemplo@gmail.com" name="email" type="email" value={email} onChange={(e) => setemail(e.target.value)}/>
              <div className="my-4">
                <textarea value={message} onChange={(e) => setmessage(e.target.value)}
                  row="3"
                  className="col-12 bg-light p-3 mt-2 border-0 rounded"
                ></textarea>
              </div>
              <div className="my-3">
                <button className="col-12 bg-black border-0 p-3 rounded text-white" name="subscribe" type="submit">
                  Submeter
                </button>
              </div>
              <div className="formppsp">
                <p>Ao preencher o formulário está a aceitar os nossos <a href="https://www.impactox-auto.com/pol%C3%ADtica-de-privacidade" target="_blank">Termos e Politicas de Privacidade</a></p>
              </div>
              </form>
            </div>
            </div>
          </div>
        </div>
        </>
                  )
                }
      </div>
    </>
  );
};

export default SingleProduct;
