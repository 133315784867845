import { CAR_DETAILS_FAIL, CAR_DETAILS_REQUEST, CAR_DETAILS_SUCCESS, CAR_LIST_FAIL, CAR_LIST_REQUEST, CAR_LIST_SUCCESS } from "../Constants/CarsConstants"

// case of state loading cars 
export const carsListReducer = (state = {cars: [] }, action) => {
    switch (action.type) {
        case CAR_LIST_REQUEST:
            return {loading: true, cars: [] };
        case CAR_LIST_SUCCESS:
            return {loading: false, 
                pages: action.payload.pages, 
                page: action.payload.page, 
                cars: action.payload.listCars};
        case CAR_LIST_FAIL:
            return {loading: false, error: action.payload };
        default:
            return state;
    }
};


export const carsDetailsReducer = (state = {car: {reviews:[]} }, action) => {
    switch (action.type) {
        case CAR_DETAILS_REQUEST:
            return {...state,loading: true };
        case CAR_DETAILS_SUCCESS:
            return {loading: false, car: action.payload};
        case CAR_DETAILS_FAIL:
            return {loading: false, error: action.payload };
        default:
            return state;
    }
};