import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux"
import { createLead } from "../../Redux/Actions/LeadsActions";
import { LEAD_CREATE_RESET } from "../../Redux/Constants/LeadsConstants";
import Message from "../LoadingError/Error";
import Loading from "../LoadingError/Loading";
import Toast from "../LoadingError/Toast";

const CalltoActionSection = () => {

  const [name, setname] = useState("")
  const [email, setemail] = useState("")
  const [phone, setphone] = useState("")
  const [message, setmessage] = useState("")
  const [car_registration, setcar_registration] = useState("")
  const [car_name, setcar_name] = useState("")

  const dispatch = useDispatch()

  const leadCreate = useSelector((state) => state.leadCreate)
  const {loading, error, lead} = leadCreate

  useEffect(()=>{

    if (lead) {
      dispatch({type:LEAD_CREATE_RESET})
      setname("")
      setemail("")
      setphone("")
      setmessage("")
      setcar_registration("")
      setcar_name("")
    }
  },[lead, dispatch])


  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(createLead(name, email, phone,  message, car_registration, car_name))
    dispatch({type:LEAD_CREATE_RESET})
    setname("")
    setemail("")
    setphone("")
    setmessage("")
    setcar_registration("")
    setcar_name("")
  }

  return (
    <>
    <Toast/>
    <div className="subscribe-section bg-with-black">
    {error && <Message variant="alert-danger">{error}</Message>}
    {loading && <Loading/>}
      <div className="container">
        <div className="row">
          <div className="col-xs-12">
            <div className="subscribe-head" onSubmit={submitHandler}>
              <h2>Precisa de mais Informações?</h2>
              <p>Deixe o seu nome e número de telemóvel e entraremos em contacto consigo! </p>
              <form className="form-section">
              <input required placeholder="Primeiro e último nome" name="text" type="text" value={name} onChange={(e) => setname(e.target.value)}/>
              <input required placeholder="Telemóvel..." name="phone" type="phone" value={email} onChange={(e) => setemail(e.target.value)}/>
              <input value="Submeter pedido de contacto!" name="subscribe" type="submit" />
              </form>
              <div className="formpp">
                <p>Ao preencher o formulário está a aceitar os nossos <a href="https://www.impactox-auto.com/pol%C3%ADtica-de-privacidade" target="_blank">Termos e Politicas de Privacidade</a></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default CalltoActionSection;
