import { LEAD_CREATE_FAIL, LEAD_CREATE_REQUEST, LEAD_CREATE_RESET, LEAD_CREATE_SUCCESS } from "../Constants/LeadsConstants";

  // CREATE CARS
  export const leadCreateReducer = (state = { leads: {} }, action) => {
    switch (action.type) {
      case LEAD_CREATE_REQUEST:
        return { loading: true };
      case LEAD_CREATE_SUCCESS:
        return { loading: false, success: true, leads: action.payload };
      case LEAD_CREATE_FAIL:
        return { loading: false, error: action.payload };
      case LEAD_CREATE_RESET:
        return {}
      default:
        return state;
    }
  };