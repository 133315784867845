import axios from "axios";
import { toast } from "react-toastify";
import { LEAD_CREATE_FAIL, LEAD_CREATE_REQUEST, LEAD_CREATE_SUCCESS } from "../Constants/LeadsConstants";
import { URL } from "../Url";

export const createLead = (name, email, phone,  message, car_registration, car_name) => async (dispatch) => {

  const ToastObjects = {
    pauseOnFocusLoss: false,
    draggable: false,
    pauseOnHover: false,
    autoClose: 10000,
  };

  try {
    dispatch({ type: LEAD_CREATE_REQUEST });


    const { data } = await axios.post(
      `${URL}/api/leads/`,
      { name, email, phone,  message, car_registration, car_name }
    );

    dispatch({ type: LEAD_CREATE_SUCCESS, payload: data });
    toast.success("Obrigado, entraremos em contacto consigo brevemente", ToastObjects);

  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;

    dispatch({
      type: LEAD_CREATE_FAIL,
      payload: message,
    });
    toast.warning("Não conseguimos submeter o pedido, por favor entre em contacto connosco por telemóvel ou email referidos nesta página.", ToastObjects);
  }
};