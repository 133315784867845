import { createStore, combineReducers, applyMiddleware} from "redux"
import thunk from "redux-thunk"
import { composeWithDevTools } from "redux-devtools-extension";
import { carsDetailsReducer, carsListReducer } from "./Reducers/CarsReducers";
import { leadCreateReducer } from "./Reducers/LeadsReducers";

const reducer = combineReducers({
    carList: carsListReducer,
    carDetails: carsDetailsReducer,
    leadCreate: leadCreateReducer
});

const initialState = {};

const middleware = [thunk];

const store = createStore(
    reducer,
    initialState,
    composeWithDevTools(applyMiddleware(...middleware))
);

export default store;