import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Pagination from "./pagination";
import {useDispatch, useSelector } from "react-redux"
import { listCar } from "../../Redux/Actions/CarsActions";
import Loading from "../LoadingError/Loading";
import Message from "../LoadingError/Error";

const ShopSection = (props) => {
  const {keyword, pagenumber} = props;
  const dispatch = useDispatch();

  const carList = useSelector((state)=> state.carList);
  const {loading, error, cars, page, pages} = carList;

  useEffect(() => {
    dispatch(listCar(keyword, pagenumber));
  }, [dispatch, keyword, pagenumber])
  return (
    <>
      <div className="container">
        <div className="section">
          <div className="row">
            <div className="col-lg-12 col-md-12 article">
              <div className="shopcontainer row">
                {
                  loading ? (
                    <div className="mb-5">
                  <Loading/>
                    </div>
                  ) : error ? (
                    <Message variant="alert-danger">{error}</Message>
                  )
                  :
                  (
                    <>
                  {cars.map((car) => (
                  <div
                    className="shop col-lg-4 col-md-6 col-sm-6"
                    key={car._id}
                  >
                    <div className="border-product">
                      <Link to={`/cars/${car._id}`}>
                        <div className="shopBack">
                          <img src={car.first_image} alt={car.car_name} />
                        </div>
                      </Link>

                      <div className="shoptext">
                        <p>
                          <h4>
                          <Link to={`/cars/${car._id}`}>
                            {car.car_name}
                          </Link>
                          </h4>
                        </p>
                        

                        <p className="priceBase">
                        <Link to={`/cars/${car._id}`}>
                            {car.month_origin}/{car.year_origin} | {car.fuel} | {car.kilometers} km
                        </Link>
                        </p>

                        <p>{car.price} €</p>
                      </div>
                    </div>
                  </div>
                ))}
                    </>
                  )
                }

                {/* Pagination */}
                <Pagination pages = {pages} page={page} keyword={keyword ? keyword : ""}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShopSection;
