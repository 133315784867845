import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";



const Header = () => {

  const [keyword,setKeyword] = useState();

  let history = useHistory();
  
  const submitHandler = (e) => {
    e.preventDefault()
    if (keyword.trim()) {
      history.push(`/search/${keyword}`);
    } else {
      history.push("/")
    };
  };

  return (
    <div>
      {/* Top Header */}
      <div className="Announcement ">
        <div className="container">
          <div className="row">
            <div className="col-md-6 d-flex align-items-center display-none">
              <p>+351 914 219 550</p>
              <p>geral@impactox.pt</p>
            </div>
            <div className=" col-12 col-lg-6 justify-content-center justify-content-lg-end d-flex align-items-center">

              <a href="https://www.impactox-auto.com/" target="_blank"> Voltar à Página Inicial </a>
 
            <a href="https://www.facebook.com/people/Impacto-X-Autom%C3%B3veis/100083409935811/" target="_blank">
                <i className="fab fa-facebook-f"></i>
            </a>
              <a href="https://www.instagram.com/impactoxautomoveis/" target="_blank">
                <i className="fab fa-instagram"></i>
              </a>
{/*
              <Link to="">
                <i className="fab fa-linkedin-in"></i>
              </Link>
              <Link to="">
                <i className="fab fa-youtube"></i>
              </Link>
              <Link to="">
                <i className="fab fa-pinterest-p"></i>
              </Link>
*/}

            </div>
          </div>
        </div>
      </div>
      {/* Header */}
      <div className="header">
        <div className="container">
          {/* MOBILE HEADER */}
          <div className="mobile-header">
            <div className="container ">
              <div className="row ">
                <div className="col-3  d-flex align-items-center">
                  <Link className="navbar-brand" to="/">
                    <img alt="logo" src="/images/logo.jpg" />
                  </Link>
                </div>

                <div className="col-9 d-flex align-items-center">
                  <form onSubmit={submitHandler} className="input-group">
                    <input
                      type="search"
                      className="form-control rounded search"
                      placeholder="Search"
                      onChange={(e) => setKeyword(e.target.value)}
                    />
                    <button type="submit" className="search-button">
                      search
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>

          {/* PC HEADER */}
          <div className="pc-header">
            <div className="row">
              <div className="col-md-5 col-4 d-flex align-items-center">
                <Link className="navbar-brand" to="/">
                  <img alt="logo" src="/images/logo.jpg" />
                </Link>
              </div>
              <div className="col-md-7 col-9 d-flex align-items-center">
                <form onSubmit={submitHandler} className="input-group">
                  <input
                    type="search"
                    className="form-control rounded search"
                    placeholder="Search"
                    onChange={(e) => setKeyword(e.target.value)}
                  />
                  <button type="submit" className="search-button">
                    search
                  </button>
                </form>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
