import axios from "axios"
import { CAR_DETAILS_FAIL, CAR_DETAILS_REQUEST, CAR_DETAILS_SUCCESS, CAR_LIST_FAIL, CAR_LIST_REQUEST, CAR_LIST_SUCCESS } from "../Constants/CarsConstants"
import { URL } from "../Url";

export const listCar = (keyword=" ", pagenumber = " ") => async (dispatch) => {
    try {
    dispatch({type: CAR_LIST_REQUEST})
    const {data} = await axios.get(`${URL}/api/cars?keyword=${keyword}&pagenumber=${pagenumber}`);
    dispatch({type: CAR_LIST_SUCCESS, payload:data });
} catch (error) {
    dispatch({
        type: CAR_LIST_FAIL, 
        payload:
            error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
    });
} };


export const listCarDetails = (id) => async (dispatch) => {
    try {
    dispatch({type: CAR_DETAILS_REQUEST})
    const {data} = await axios.get(`${URL}/api/cars/${id}`);
    dispatch({type: CAR_DETAILS_SUCCESS, payload:data});
} catch (error) {
    dispatch({
        type: CAR_DETAILS_FAIL, 
        payload:
            error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
    });
} };